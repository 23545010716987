import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout/Layout";
import Components from "../components/dynamic";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import UnAuthorizedUser from "../sections/dynamic-page/UnAuthorizedUser";

function HomePage({location, data }) {
  const components = data.dynamicPages.versions?.components || [];
  const loadIframe = data?.dynamicPages?.load_iframe || false  
  const pageUrl = data?.dynamicPages?.url || ''
  const is_customers_only = data?.dynamicPages?.is_customers_only || false  
  const { user } = useSelector((state) => state.auth);


  // useEffect(() => {
  //   if(!user?.accessToken && is_customers_only) {
  //     (navigate("/403"))
  //   }
  // }, []);

  return ( 
    <>
      {!user?.accessToken && is_customers_only ?
        (<UnAuthorizedUser />) :
        (          
          <>                
          {pageUrl !== '/' && pageUrl !== '' ?
            (<div className="container">
              <Breadcrumbs location={location} url={pageUrl} title={data?.dynamicPages?.name.toUpperCase()} />
            </div>)
            :
            (null)
          }
  
          {components.length ? components.map((item, index) => {
            return <Components data={item} loadIframe={loadIframe} pageUrl={pageUrl} key={`${item.code}-${index}`} />;
          })
          : null}               
        </> 
          )
      }               
      
      </>
  )
}

export const Head = ({ data }) => {
  const seoDetails = data.dynamicPages.versions?.seo_details || {};

  return (
    <>
      <meta lang="en" />
      <title>{seoDetails?.meta_title}</title>
      <meta
        name="description"
        content={seoDetails?.meta_description || ""}
      />
    </>
  );
};

export const query = graphql`
  query GetDynamicPagesContent($id: String!) {
    dynamicPages(id: { eq: $id }) {
      id
      name
      load_iframe
      is_customers_only
      url      
      versions {
        components {
          code
          variant {          
            config {
              ...dynamicPagesVersionsComponentsVariantConfigFragment
            }
            admin_layout
          }
        }
        seo_details {
          page_name
          page_url
          meta_title
          meta_description
        }
      }
    }
  }

  fragment dynamicPagesVersionsComponentsVariantConfigFragment on dynamicPagesVersionsComponentsVariantConfig {
    side_images {
      image_url
      title
      visibility
      url
    }
    slider {
      image_url
      title
      visibility
      url
    }
    alt
    banners {
      image_url
      mobile_image_url
      text
      title
      url
      visibility
    }
    data
    image_url
    mobile_image_url
    logos {
      image_url
      title
      url
      visibility
    }
    disable_bg
    sub_title
    title
    url
    visibility
    api_endpoint
  }
`;

HomePage.Layout = Layout
export default HomePage;