import React, { useEffect } from "react";
import { navigate } from "gatsby";


const UnAuthorizedUser = () => {
  
  useEffect(() => {   
      (navigate("/403"))    
  }, []);

  return (
    <></>
  );
};

export default UnAuthorizedUser;
